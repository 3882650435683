// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-property-for-sale-js": () => import("./../../../src/templates/propertyForSale.js" /* webpackChunkName: "component---src-templates-property-for-sale-js" */),
  "component---src-templates-property-rental-js": () => import("./../../../src/templates/propertyRental.js" /* webpackChunkName: "component---src-templates-property-rental-js" */)
}

